// --------------------------------------

// color bases
// http://bootstraplovers.com/templates/assan-v2.5/main-template/buttons.html
// http://bootstraplovers.com/templates/assan-v2.5/main-template/index.html#
// http://bootstraplovers.com/templates/assan-v2.5/main-template/me.html
// http://wrapbootstrap.com/preview/WB0RK211T
// http://barunka.ondrejsvestka.cz/
// implement some of the things in this site
// footer at the bottom

// or the blue and gray from here
// http://barunka.ondrejsvestka.cz/

// http://wrapbootstrap.com/preview/WB0RK211T

// Gray and white tones
// template online
// https://color.adobe.com/IT-website-color-theme-8962610/
// https://color.adobe.com/Copy-of-IT-website-color-theme-8962709/ (Improvements Andrew)

// maybe not enough contrast
// $white: #F7F7F7;
// $off-white: #f4f4f4; //#f7f7f7;

// _____________________________________
//
// Theme main colors
// ________________________________________

// C blue: #1A1F4C;
// C orange: #FDC245

$primary-color: #3c6090;
$secondary-color: #808080;
$call-to-action: #FFC600;
$CV-call-to-action: #EEAB3A;

$white: #FFFFFF;
$soft-white: #F4F4F4;

// Text colors, gray block
$grey-soft: #8f8f8f;        // slightly lighter for less attention to items #f7f7f7;
$grey-dark: #3f3f3f;        // header text
$grey-medium: #676767;      // normal text


$shadow: 0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12),0 2px 4px -1px rgba(0,0,0,0.3);

// Fonts to consider for headers
$body-font: 'Roboto', sans-serif, serif !important;
$title-font: 'Titillium Web', sans-serif;

// ____________________________________________
//
//      General styles
// ____________________________________________

// Bootstrap
// http://getbootstrap.com/components/#navbar-fixed-top

* {
  // font-size: 66,7%;
}

html {
  // width: 98.8vw;
    font-size: 66.7%;
}

body {
  background-color: $white;
  color: $grey-medium;
  font-family: $body-font;
  font-size: 2rem;
  line-height: 2.5rem;
  position: relative;
}

h1 {
  // font-family: 'Roboto Slab', sans-serif, serif; old one
  font-family: $title-font;
  color: $primary-color;
  font-size: 3.5vw;
  text-align: center;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: 300;
}

h1:before {
  content: '';
  display: block;
  margin-top: -70px;
  height: 70px;
  visibility: hidden;
}

h2 {
  font-family: $title-font;
  font-size: 2vw;
  margin-bottom: 20px;
}

.noheight { height: 0px; }


// ____________________________________________
//
//      Landing styles
// ____________________________________________

.landing {
  background-attachment: initial;
  background-color: #111;
  background-image: url('../images/working-on-laptop-sun.jpg');
  background-position: center center;
  background-size: cover;
  color: #fff;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
  display: flex;

  &:after {
    // background: rgba(0, 0, 0, 0) url("../images/retro-overlay.png") repeat scroll 0 0;
    background: rgba(0, 0, 0, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .intro-message {
    font-family: 'Roboto Slab', sans-serif, serif;
      font-family: $title-font;
    font-size: 2.75rem;
    background-color: $soft-white;
    // border-radius: 3px;
    color: $grey-dark;
    display: inline-block;
    padding: 10px 12% 40px;
    margin: 75px auto auto;
    z-index: 10;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
  }
  hr {
    margin: -10px 0 0 0;
    border: 1px solid lighten($grey-soft, 25%);
  }
  h2 {
    font-weight: 300;
    color: $CV-call-to-action;
  }
  a { transition: all 0.5s ease-in-out; }
  a:hover, a:active, a:focus { color: $white; }
}

.cta-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 40px;

  .btn {
    border-radius: 0px;
    padding: 10px 25px;
    background-color: $primary-color;
    border: 0px solid $white;
    color: $call-to-action;
    font-family: 'Roboto', sans-serif, serif;
    font-weight: 400;
    font-size: 2.25rem;
    margin-bottom: 5px;
    transition-property: color, border-color;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    border: 2px solid transparent;

    &:hover {
      color: $primary-color;
      background-color: $soft-white;
      border: 2px solid $primary-color;
      // border-bottom: 5px solid darken($call-to-action,10%);
      // border-right: 5px solid darken($call-to-action,10%);
    }
  }
  .btn:first-child {
    margin-right: 5px;
  }


}

.down-arrow {
  bottom: 5%;
  color: $white;
  cursor: pointer;
  font-size: 3.5rem;
  left: 49.3%;
  // padding: 15px 20px;
  position: absolute;
  z-index: 100;
}


// ____________________________________________
//
//      Navigation Styles
// ____________________________________________

.sticky-wrapper {         // used to stick the nav menu to the top of the page
  width: 100%;
    z-index: 999;
}

.navbar,
.navbar-default {
  width: 100%;
  background-color: $primary-color;
  opacity: 0.98;
  border: none;
  border-radius: 0;
  z-index: 999;
  box-shadow: $shadow;
}
.navbar-right{
  color: $call-to-action;
}
.navbar-default .navbar-brand{
  padding-left: 20px;
  color: $CV-call-to-action !important;
  font-family: $title-font;
  font-size: 2.5rem;
}

.navbar-nav li a{
  color: $call-to-action !important;
  background-color: inherit !important;
  border-bottom: 3px solid transparent;
  font-weight: 300;
  transition: all 0.5s ease-in-out;
  font-family: $title-font;
}

.navbar-nav > .active > a,
.navbar-nav > .active > a:hover,
.navbar-nav li a:hover{
  border-bottom: 2px solid $call-to-action;
}

.content-area{                  // used for each section of the page
  margin-top: 70px;
}

.about-area,
.services-area,
.portfolio-area,
.ial-area,
.contact-area{
  // border: 1px solid #aaa;
  padding: 50px 40px;
  display: block;

}


// ____________________________________________
//
//      About area
// ____________________________________________

.about-area {
  padding: 80px 40px 50px 40px;

  .row {
    margin: 0px;
  }
  h1 {
    margin-bottom: 15px;
  }
  h2 {
    margin-top: 0px;
    margin-bottom: 30px;
    flex: 0 0 100%;
  }
}

.top-line {
  position: relative;
  background-color: $soft-white;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid $primary-color;
}

.top-line:after {
  content: '';
  display: flex;
  margin: auto;

  position: absolute;
  top: 0;
  left: 0;
  height: 105%;
  width: 100px;
  background-image:
    linear-gradient(
      90deg,
      rgba(255,255,255,1),
      rgba(255,255,255,0) 100px

    );
  z-index: 3;
}

.top-line-right-block {
  float: right;
  background-color: #e4e4e4;
  width: 50px;
  height: 50px;
  // z-index: 999;
}

// structure
//   .container-flex
//     .main-flex
//       .header-flex
//         .title-flex
//         .img-flex
//       .about-me
//     .right-line-flex

.container-flex{
  display: flex;
  align-items: stretch;
}

.main-flex{
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 25px 25px 0px 0px;
  overflow: hidden;
}

.main-block {
  display: block;
  width: 100%;
  p {
    padding: 0 30px 10px;
  }
}

.top-flex{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

.title-text-flex{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.img-flex {
  display: block;
}

.img-mobile {
  display: none;
  margin-bottom: 10px;
}

.portfolio-area img {
  padding: 10px;
}

.about-me {
  padding: 0 20px;
  text-align: center;
}

.right-line-flex {
  position: relative;
  background-color: $soft-white;
  border-left: 1px solid $primary-color;

  width: 50px;
  min-width: 50px;
}

.right-line-flex:after {
  display: flex;
  margin: auto;
  flex-flow: column wrap;
  align-content: flex-end;
  content: '';

  position: absolute;
  bottom: 0;
  left: -1px;
  height: 100px;
  width: 105%;
    background-image:
      linear-gradient(
        0deg,
        rgba(255,255,255,1),
        rgba(255,255,255,0) 100px

      );
    z-index: 3;
}


// ____________________________________________
//
//      Services Area
// ____________________________________________

.services-area {
  i {
  border: 3px solid $primary-color;
  border-radius: 50%;
  color: $primary-color;
  font-size: 4rem;
  margin-top: 30px;
  padding: 20px;
  }

  h2 {
    color: $primary-color;

  }
}


// ____________________________________________
//
//      Call out box styles
// ____________________________________________

.parallax-call-out-background {
    // The image used
    background: url("../images/brushed_aluminium_darkened.png"); background-repeat: repeat;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;

    // Set a specific height
    display: flex;
    height: 300px;
    margin: 50px 0;
    font-family: $title-font;
}

.parallax-call-out-overlay {
  width: 70%;
  margin: auto;

  font-size: 3.5rem;
  line-height: 6rem;
  text-align: center;
  color: $soft-white;
}


// ____________________________________________
//
//      Portfolio Styles
// ____________________________________________

.portfolio-area {
  // background-color: $secondary-color;
  h1 {
    // color: $soft-white;
  }
  img {
    width: 100%;
  }
.project-grid {
  margin-top: 25px;
}

  .btn-group{
    width: 100%;
    text-align: center;
    // margin: 0 auto
    margin: 25px 0 0;
  }

  .btn {
    border: 1px solid transparent;
    font-size: 1.5rem;
    background-color: $primary-color;
    color: $call-to-action;
    float: none;
    margin-top: 3px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $primary-color;
    }

    &:hover,
    &:focus,
    &:active {
      color: $primary-color;
      background-color: $white;
      border: 1px solid $primary-color;

    }
  }

  .is-checked {
    color: $primary-color;
    background-color: $white;
    border: 1px solid $primary-color;

  }

  .project-grid {
    display: block;
  }

  .project {
    color: $grey-dark;
    // width: 33.33%;
    width: 100%;
    text-align: center;
    padding: 4% 2%;

    a {
      color: $grey-dark;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
    }
    .project-action-icons a:hover {
      color: $call-to-action;
    }
    .col-md-4 {
      padding: 0 30px;
    }
  }

  .project-innerwrapper {
    background-color: $soft-white;
    overflow: hidden;
    box-shadow: $shadow;
  }

  .title {
    font-family: $title-font;
    font-size: 2.25rem;
    padding: 15px 0;
  }

  .project-action-icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    font-size: 1.75rem;

    a {
      text-decoration: none;
      padding: 15px 5px 10px;
      margin: auto;
      display: block;
      align-items: center;
      text-align: center;
      width: 100%;
    }
  }
  .fa {
    display: block;
    font-size: 2.5rem;
  }
  .img-wrapper {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: 100%;
      background-color: rgba(250,250,250, 0.3);

    }
  }

}


// ____________________________________________
//
//      Portfolio - on hover zoom effect
// ____________________________________________

div.img-responsive {
  width: 425px;
  height: 266px;
  position: relative;
  margin: 0 auto;
  background-size: cover;
  transition: all 1.5s ease-in-out;
  background-position: center;
}

.img-wrapper {
  overflow: hidden;
  width: 95%;
  height: 100%;
  margin: 0 auto;
}

.project-innerwrapper:hover div.img-responsive {
  transform: scale(1.1);
}

div.img-responsive::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(50, 40, 40, 0.22);
  opacity: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.project-innerwrapper:hover div.img-responsive::before {
  opacity: 1;
}

.project-innerwrapper a {
  text-decoration: none;
}

// ____________________________________________
//
//      Modal styles
// ____________________________________________

.modal{
  overflow: hidden;
  background: rgba(250, 250, 250, 0.65) repeat scroll center center;
  // background: url($white-background-striped);
   //background: rgba(50, 50, 50, 0.75) url($white-background-striped) repeat scroll center center;
  color: $grey-medium;
  .btn {
    cursor: pointer;
  }
  .btn .code {
  //   color: $blue;
  }
  .btn .site {
//     color: $text-color-grey;
  }
  .btn .myclose {
  //   color: $call-to-action;
  }

  .tech-wrapper {
    display: block;
    text-align: center;
    margin: 10px 0px;
  }
  .tech {
    display: inline-block;
    padding: 0px 10px;
    margin-left: 3px;
    margin-top: 3px;
    background-color: lighten($grey-soft, 20%);
    color: $white;
    &:first-child{
      margin-left: 0px;
      border-radius: 3px 0 0 3px;
    }
    &:last-child{
      border-radius: 0 3px 3px 0;
    }
  }
}

.modal-content {
  border-radius: 0;
  padding-bottom: 0;

}

.modal-body {
  padding: 15px 15px 5px;
}

.modal-header{
  border-bottom: medium $secondary-color;
  background-color: $primary-color;
  h2 {
    color: $soft-white;
  }
  .close {
    font-size: 4rem;
    margin: 0;
  }
}

.modal-title {
  font-size: 3rem;
  color: $grey-medium;
  text-align: center;
  padding: 0;
  border-bottom: none;
}

.modal-img-wrapper {
  border-radius: 2px;
  margin: 10px auto 20px;
  cursor: pointer;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

.modal-footer {
  border: none;
  text-align: center;
  padding-top: 0;
  display: flex;
  justify-content: space-around;
  .fa {
    display: block;
    width: 100%;
    font-size: 3.5rem;
  }
  .fa-plus-circle {
    transform: rotate(45deg);
  }
  .btn {
    font-size: 2rem;
    display: inline-block;
  }
  a:hover{
    color: lighten($call-to-action, 10%);
  }
}

.hide {
  display: none !important;
}
.code {
  display: inline-block;
}


// ____________________________________________
//
//      testimonial area
// ____________________________________________

// some styling ideas --- https://codepen.io/WhizofaWiz/pen/mJdByp?editors=1100

.testimonial-area {
  font-family: $body-font;
  font-size: 2.5rem;
  line-height: 3rem;
  background-color: $grey-dark;
  background: url("../images/brushed_aluminium_darkened.png"); background-repeat: repeat;
  // color: darken($soft-white,20%);
  padding: 50px 0 50px;
  margin: 50px 0;

  .slick-dots { padding-top: 25px; }

  h1 { margin-top: -100px; height: 100px; }
}

.testimonial-wrapper {
  width: 90%;
  margin: 0 auto;
}

.box blockquote {
	margin: 0 25px;
	background: $white;
	padding: 50px;
	position: relative;
	border: none;
  text-align: center;
  font-size: 2rem;
  line-height: 3.25rem;
}

.box cite {
  display: block;
  position: relative;
  right: 25px;
  top: 15px;
  text-align: right;
	font-size: 1.75rem;
}

// ____________________________________________
//
//      Contact area
// ____________________________________________

.contact-area {
  padding: 5rem 15rem 3rem;
  .row {
    margin: 0;
  }
  .intro {
    margin-bottom: 2em;
    text-align: center;
  }
  input[type=text], textarea.form-control {
    transition: all 0.25s ease-in-out !important;
  }
  .btn-primary{
    background-color: $primary-color;
    color: $call-to-action;
    border: 2px solid transparent;
    font-size: 1.75rem;
    padding: 10px 25px;
    border-radius: 0px;
    margin-top: -5px;
    transition: all 0.5s ease-in-out;

    .fa-paper-plane { margin: 0 5px 0 -5px; }

    &:hover {
      border: 2px solid $primary-color;
      background-color: $white !important;
      color: $primary-color;
    }
  }
  .social {
    width: 100%;
    padding: 10px;
    margin: 0 auto;

    .social-icons {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      justify-content: center;
    }
    a {
      font-size: 1.65em;
      overflow: hidden;
      border-radius: 3px;
      border: none;
      display: table;
      float: left;
      margin: 0 10px 10px 0;
      text-decoration: none;
      transition: all 0.5s ease-in-out;
      &:hover {
        transform: scale(1.1);
      }
    }
    i {
      height: 50px;
      width: 50px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }
    .fa { transition: all 0.5s ease-in-out; color: $primary-color; }
    .fa:hover { border: none; }

    .fa-github:hover { background-color: #222; color: #fafafc; }
    .fa-linkedin:hover { background-color: #0077b5; color: #fafafc; }
    .fa-twitter:hover { background-color: #4099FF; color: #fafafc; }
    .fa-free-code-camp:hover { background-color: #006400; color: #fafafc; }
    .fa-codepen:hover { background-color: #222; color: #fafafc; }
    .fa-stack-overflow:hover { background-color: #F48024; color: #fafafc; }
  }
}

label {
  display: block;
  color: tomato;
  font-size: 1.25rem;
  font-weight: 500;
  max-width: 100%;
  padding-left: 10px;
  margin-top: -40px;
  margin-bottom: 10px;
}

.form-control{
  font-size: 1.75rem;
  border-radius: 0px;
  height: 45px;
  padding: 10px;
  margin-bottom: 40px;

  &:focus {
    border: 1px solid $primary-color;
    // -webkit-box-shadow: $shadow;
    // -moz-box-shadow: $shadow;
    // box-shadow: $shadow;
  }
}

.success-background {
  display: flex;
  height: 150px;
  width: 100%;
  // background-color: #ccc;
  z-index: 99;
}

.success-message {
  font-size: 2.25rem;
  text-align: center;
  max-width: 650px;
  margin: 4.5em auto;
  background-color: #eeffee;
  padding: 20px;
  box-shadow: $shadow;
}


// ____________________________________________
//
//      Footer
// ____________________________________________

.footer {
  height: 200px;
  background-color: $grey-dark;
  background: url("../images/brushed_aluminium_darkened.png"); background-repeat: repeat;
  color: darken($soft-white,20%);
  font-size: 2rem;
  display: flex;

  p {
    margin: auto;
    padding: 10px;
    text-align: center;
  }
}


// ____________________________________________
//
//      development (temp)
// ____________________________________________

.border {
  border: 1px solid #ccc;
}

.hide {
  display: none;
}


// ____________________________________________
//
//      Animations
// ____________________________________________

.bounce {
 animation: bounce 4s infinite;
 -webkit-animation: bounce 4s infinite;
 -moz-animation: bounce 4s infinite;
 -o-animation: bounce 4s infinite;
}

@-webkit-keyframes bounce {
 0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
 40% {-webkit-transform: translateY(-30px);}
 60% {-webkit-transform: translateY(-15px);}
}

@-moz-keyframes bounce {
 0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
 40% {-moz-transform: translateY(-30px);}
 60% {-moz-transform: translateY(-15px);}
}

@-o-keyframes bounce {
 0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
 40% {-o-transform: translateY(-30px);}
 60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
 0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
 40% {transform: translateY(-30px);}
 60% {transform: translateY(-15px);}
}


// ____________________________________________
//
//      Media queries
// ____________________________________________

@media (max-width: 950px){
  .social-icons {
    margin-top: 2em;
  }
}

@media (max-width: 900px){
  // about
  .top-flex {
    .title-text-flex {
      display: block;
      padding: 0 10px;
    }
    .img-mobile {
      display: inline-block;
      margin: 5px auto 30px auto;
      border-radius: 50%;
    }
    img {
      display: none;
    }
  }
  .title-text-flex h2 {
    font-size: 2.2vw;
  }

  // contact
  .contact-area {
    padding: 50px 10rem;
  }
}

// Portfolio
@media (min-width: 768px) {
  .portfolio-area .project {
    width: 33.33%;
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .portfolio-area .project {
    width: 50%;
    font-size: 1rem;
  }
}

@media (max-width: 640px) {
  .portfolio-area .project {
    width: 60%;
    margin: 0 20%;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .portfolio-area .project {
    width: 95%;
    margin: 0 2.5%;
    padding: 2em 5px;
  }
}

@media (max-width: 640px) {
  // general
  .about-area,
  .services-area,
  .portfolio-area,
  .nals-area{
    padding: 50px 10px;
  }

  // // landing page
  .cta-buttons .btn{
      font-size: 1.5rem;
  }
  .landing .intro-message{
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    padding: 10px 10% 40px;
  }
  // about
  .right-line-flex {
    width: 15px;
    min-width: 15px !important;
  }
  .top-line-right-block {
    width: 15px;
    height: 15px;
  }
  .top-line {
    height: 15px;
  }
  h1 {
    font-size: 2.5rem;
  }
  .title-text-flex h2 {
    font-size: 1.7rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  .main-block p {
    text-align: left;
    padding: 0;
  }

  .parallax-call-out-background {
    height: 300px;
  }
  .parallax-call-out-overlay {
    font-size: 2.25rem;
    line-height: 3.5rem;
  }

  // contact
  .contact-area {
    padding: 50px 2rem;
  }

  .success-message {
    font-size: 2rem;
  }
}

@media (max-width: 900px ){
  h2 {
    font-size: 2.2rem !important;
  }
}
